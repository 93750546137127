.App {
  text-align: center;
  background-color: #282c34;
  height: 100%;
}
a {
  color: rgb(100, 100, 223);
}
html, body{
  height: 100%;
}
.spacer{
  height: 50px;

}

.textBox{
  color: white;
}
.ct-items-w{
  width: 300px;
 text-align: left;
}
.border-box{

  background-color: white;
  /* align-items: center; */
  flex-direction: row;
  justify-content: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bt-1{
  margin-top: 10px;
}
.center-center{
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  place-content: center space-around;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-image {
  /* background-image: url("/public/assets/images/lander.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 800px;
  height: 1200px; */
  width: 100%;
  margin: 40px 40px;
}

.App-cont {
  width: 100%;
  height: auto;
}

.img {
  width: 40%;
  height: auto;
}
.ct-width{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}
.sub-title-box{
  font-size: 18px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sb-txt{
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
}
.it-txt{
  font-style: italic;
  font-size: smaller;
  color: white;

}
.in-line{
  display: inline-block !important;
}
.tl-txt{
  color: white;
  width: 75%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start center;
  justify-content: center;
  line-height: 33px;
}
.img-1{
  width: 806px;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 480px){
  .img-1{
    width: 100%;
  }
  .tl-txt{
    width: 100%;
  }
  .ct-width{
    width: 90%;
  }
  body, html {
    width: 100%;
  }
  .App-header{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}